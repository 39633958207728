import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { Link, Navigate } from "react-router-dom";
import { More, Person } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { selectUser, selectUserIsAdmin } from "../selectors/selectors";
import { userSet } from "../actions/appActions";
import {
  removeTimeCheck,
  saveTimeCheck,
  testTimeCheck,
} from "../auth/timeCheck";

const pages = [
  {
    url: "/",
    title: "Výpůjčky",
  },
  {
    url: "/calendar",
    title: "Kalendář",
  },
  {
    url: "/items",
    title: "Předměty",
  },
  {
    url: "/users",
    title: "Uživatelé",
  },
];

const getPages = (userIsAdmin) => {
  return pages.filter((p) => {
    return userIsAdmin || p.url !== "/users";
  });
};

const Layout = (props) => {
  const { children } = props;

  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const userIsAdmin = useSelector(selectUserIsAdmin);

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  React.useEffect(() => {
    if (testTimeCheck()) {
      saveTimeCheck();
    } else {
      removeTimeCheck();
      dispatch(userSet(null));
    }
  }, []);

  if (!user) {
    return <Navigate to="/login" />;
  }

  return (
    <>
      <AppBar position="static" sx={{ bgcolor: "#ffffff" }}>
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                {getPages(userIsAdmin).map((page) => (
                  <Link to={page.url} key={page.url}>
                    <MenuItem>
                      <Typography textAlign="center">{page.title}</Typography>
                    </MenuItem>
                  </Link>
                ))}
              </Menu>
            </Box>
            <img
              style={{
                height: 40,
              }}
              src={
                "https://s3.eu-central-1.amazonaws.com/uploads.darujme.cz/organization/logo/tangram.jpg-eBj7wTi2UFnCi5GFeR5jclT8.jpg"
              }
            />
            <Box
              sx={{ flexGrow: 1, ml: 1, display: { xs: "none", md: "flex" } }}
            >
              {getPages(userIsAdmin).map((page) => (
                <Button
                  key={page.url}
                  component={Link}
                  to={page.url}
                  color={"primary"}
                  onClick={handleCloseNavMenu}
                  sx={{
                    my: 2,
                    display: "block",
                  }}
                >
                  {page.title}
                </Button>
              ))}
            </Box>

            <Box>
              <Button
                size={"small"}
                onClick={handleOpenUserMenu}
                endIcon={<Person />}
              >
                {user.email}
              </Button>

              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <MenuItem
                  onClick={() => {
                    removeTimeCheck();
                    dispatch(userSet(null));
                    handleCloseUserMenu();
                  }}
                >
                  <Typography textAlign="center">Odhlásit se</Typography>
                </MenuItem>
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <Container maxWidth="xl" sx={{ pt: 3 }}>
        {children}
      </Container>
    </>
  );
};

export default Layout;
