import { Provider } from "react-redux";
import { ThemeProvider } from "@mui/material";
import LayoutUnsecured from "./layouts/LayoutUnsecured";
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import store from "./store/store";
import { theme } from "./theme/theme";
import Item from "./components/Item/Item";
import Calendar from "./components/Calendar/Calendar";
import UserForm from "./components/User/UserForm";
import User from "./components/User/User";
import SnackbarNotifier from "./components/SnackbarNotifier/SnackbarNotifier";
import ItemForm from "./components/Item/ItemForm";
import Login from "./components/Login/Login";
import Home from "./components/Home/Home";
import LayoutSecured from "./layouts/LayoutSecured";
import BorrowingForm from "./components/Borrowing/BorrowingForm";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { cs } from "date-fns/locale";

function App() {
  return (
    <Provider store={store}>
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={cs}>
        <ThemeProvider theme={theme}>
          <Router>
            <LayoutUnsecured>
              <Routes>
                <Route
                  path="/"
                  element={
                    <LayoutSecured>
                      <Home />
                    </LayoutSecured>
                  }
                />
                <Route
                  path="/login"
                  element={
                    <LayoutUnsecured>
                      <Login />
                    </LayoutUnsecured>
                  }
                />
                <Route
                  path="/calendar"
                  element={
                    <LayoutSecured>
                      <Calendar />
                    </LayoutSecured>
                  }
                />
                <Route
                  path="/users"
                  element={
                    <LayoutSecured>
                      <User />
                    </LayoutSecured>
                  }
                />
                <Route
                  path="/items"
                  element={
                    <LayoutSecured>
                      <Item />
                    </LayoutSecured>
                  }
                />
              </Routes>
              <UserForm />
              <ItemForm />
              <BorrowingForm />
              <SnackbarNotifier />
            </LayoutUnsecured>
          </Router>
        </ThemeProvider>
      </LocalizationProvider>
    </Provider>
  );
}

export default App;
