import React from "react";
import {
  Box,
  Button,
  IconButton,
  Popover,
  Typography,
  TextField,
} from "@mui/material";
import {
  useGetBorrowingsAllQuery,
  useGetItemsAllQuery,
  useGetUsersAllQuery,
} from "../../api/api";
import { ArrowCircleLeft, ArrowCircleRight } from "@mui/icons-material";
import { formBorrowingSet, formItemSet } from "../../actions/appActions";
import { buildNewObject, ItemModel } from "../../library/item";
import { useDispatch, useSelector } from "react-redux";
import { createNewBorrowing } from "../../library/borrowing";
import { selectUserIsAdmin } from "../../selectors/selectors";
import { getBorrowingColor, normalizeDt } from "../../utils/borrowingLogic";
import { DatePicker } from "@mui/lab";

const width = 40;

// export const normalizeDt = (dt) => {
//   dt.setHours(3, 0, 0, 0);
//   return dt;
// };

const isNow = (dt) => {
  const now = new Date();
  return (
    dt.getFullYear() === now.getFullYear() &&
    dt.getMonth() === now.getMonth() &&
    dt.getDate() === now.getDate()
  );
};
const isWeekday = (dt) => {
  return [0, 6].includes(dt.getDay());
};

const getDayName = (dt) => {
  const dn = ["ne", "po", "út", "st", "čt", "pá", "so"];
  return dn[dt.getDay()];
};
const getMonthName = (dt) => {
  return [
    "leden",
    "únor",
    "březen",
    "duben",
    "květen",
    "červen",
    "červenec",
    "srpen",
    "září",
    "říjen",
    "listopad",
    "prosinec",
  ][dt.getMonth()];
};

const Calendar = () => {
  const userIsAdmin = useSelector(selectUserIsAdmin);

  const {
    data: borrowingList = [],
    error: borrowingError,
    isLoading: borrowingIsLoading,
  } = useGetBorrowingsAllQuery();

  const {
    data: itemList = [],
    error: itemError,
    isLoading: itemIsLoading,
  } = useGetItemsAllQuery();

  const {
    data: userList = [],
    error: userError,
    isLoading: userIsLoading,
  } = useGetUsersAllQuery();

  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedBorrowing, setSelectedBorrowing] = React.useState(null);

  const handleClick = (event, borrowing) => {
    setAnchorEl(event.currentTarget);
    setSelectedBorrowing(borrowing);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const [boundaryStartDate, setBoundaryStartDate] = React.useState(() => {
    const dt = new Date();
    dt.setDate(1);
    // dt.setDate(dt.getDate() - 7);
    return normalizeDt(dt);
  });
  const [boundaryEndDate, setBoundaryEndDate] = React.useState(() => {
    const dt = new Date();
    dt.setDate(1);
    dt.setDate(dt.getDate() + 30);
    return normalizeDt(dt);
  });

  const moveCalendar = (i) => {
    setBoundaryStartDate((prev) => {
      const dt = new Date(prev);
      dt.setMonth(dt.getMonth() + i);
      return dt;
    });
    setBoundaryEndDate((prev) => {
      const dt = new Date(prev);
      dt.setMonth(dt.getMonth() + i);
      return dt;
    });
  };

  const rows = () => {
    return itemList
      .filter((item) => {
        return item.isAvailableForBorrowing;
      })
      .sort((a, b) => {
        return a.inventoryNumber * 1 - b.inventoryNumber * 1;
      });
  };

  const columns = () => {
    const columns = [];
    const diffTime = Math.abs(boundaryEndDate - boundaryStartDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    for (let i = 0; i <= diffDays; i++) {
      const dt = new Date(boundaryStartDate);
      dt.setDate(dt.getDate() + i);
      columns.push(dt);
    }
    return columns;
  };

  const getBox = (item, date) => {
    const index = borrowingList.findIndex((b) => {
      const dateFrom = normalizeDt(new Date(b.estimatedDateFrom));
      const dateTo = normalizeDt(new Date(b.estimatedDateTo));

      return (
        b.item === item["@id"] &&
        dateFrom <= date &&
        dateTo >= date &&
        !b.dateTo
      );
    });

    if (index === -1) {
      return (
        <Box
          sx={{
            width: width,
            border: "solid 1px rgba(0, 0, 0, 0.06)",
            boxSizing: "border-box",
            backgroundColor: "#fff",
            padding: 1,
          }}
          onClick={() => {
            const borrowing = createNewBorrowing();
            borrowing.item = item["@id"];
            borrowing.estimatedDateFrom = date;
            dispatch(formBorrowingSet(borrowing));
          }}
        />
      );
    }

    const borrowing = borrowingList[index];

    const userIndex = userList.findIndex((u) => u["@id"] === borrowing.user);
    const user = userIndex !== -1 ? userList[userIndex] : "";

    const dateFrom = Math.max(
      normalizeDt(new Date(boundaryStartDate)).getTime(),
      normalizeDt(new Date(borrowing.estimatedDateFrom)).getTime()
    );
    const dateTo = Math.min(
      normalizeDt(new Date(boundaryEndDate)).getTime(),
      normalizeDt(new Date(borrowing.estimatedDateTo)).getTime()
    );

    const diffTime = Math.abs(dateTo - dateFrom);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 1;

    if (dateFrom === date.getTime()) {
      return (
        <Box
          sx={{
            width: width * diffDays,
            border: "solid 1px rgba(0, 0, 0, 0.06)",
            boxSizing: "border-box",
            backgroundColor: "#fff",
            pt: 0.5,
            pb: 0.5,
            pl:
              normalizeDt(new Date(borrowing.estimatedDateFrom)).getTime() <
              normalizeDt(new Date(boundaryStartDate)).getTime()
                ? 0
                : 0.5,
            pr:
              normalizeDt(new Date(borrowing.estimatedDateTo)).getTime() >
              normalizeDt(new Date(boundaryEndDate)).getTime()
                ? 0
                : 0.5,
          }}
        >
          <Box
            sx={{
              borderTopLeftRadius:
                normalizeDt(new Date(borrowing.estimatedDateFrom)).getTime() <
                normalizeDt(new Date(boundaryStartDate)).getTime()
                  ? 0
                  : 16,
              borderBottomLeftRadius:
                normalizeDt(new Date(borrowing.estimatedDateFrom)).getTime() <
                normalizeDt(new Date(boundaryStartDate)).getTime()
                  ? 0
                  : 16,
              borderTopRightRadius:
                normalizeDt(new Date(borrowing.estimatedDateTo)).getTime() >
                normalizeDt(new Date(boundaryEndDate)).getTime()
                  ? 0
                  : 16,
              borderBottomRightRadius:
                normalizeDt(new Date(borrowing.estimatedDateTo)).getTime() >
                normalizeDt(new Date(boundaryEndDate)).getTime()
                  ? 0
                  : 16,
              width: "100%",
              height: "100%",
              backgroundColor: getBorrowingColor(borrowing),
              fontSize: 14,
              pt: 0.5,
              pb: 0.5,
              boxSizing: "border-box",
              pl: 1,
              pr: 2,
              color: "#fff",
              cursor: "pointer",
            }}
            onClick={(event) => {
              handleClick(event, borrowing);
              // if (userIsAdmin) {
              //   const { user, item, ...rest } = borrowing;
              //   dispatch(formBorrowingSet(rest));
              // }
            }}
          >
            {user.email}
          </Box>
        </Box>
      );
    }

    return <></>;
  };

  return (
    <>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        {!!selectedBorrowing && (
          <Box sx={{ p: 1 }}>Event: {selectedBorrowing.event}</Box>
        )}
      </Popover>
      <Box>
        <Box>
          <Button
            onClick={() => dispatch(formBorrowingSet(createNewBorrowing()))}
            color={"primary"}
            variant={"contained"}
            sx={{ mb: 3, mr: 3 }}
          >
            Nová výpůjčka
          </Button>
          {userIsAdmin && (
            <Button
              onClick={() => dispatch(formItemSet(buildNewObject()))}
              color={"primary"}
              variant={"contained"}
              sx={{ mb: 3 }}
            >
              Nový předmět
            </Button>
          )}
        </Box>

        <Box sx={{ display: "flex", mb: 2, fontSize: 13 }}>
          <Box sx={{ display: "flex", mr: 2 }}>
            <Box
              sx={{
                width: 15,
                height: 15,
                borderRadius: "50%",
                backgroundColor: "orange",
                mr: 0.5,
              }}
            />
            <Box>Výpůjčka v budoucnosti</Box>
          </Box>
          <Box sx={{ display: "flex", mr: 2 }}>
            <Box
              sx={{
                width: 15,
                height: 15,
                borderRadius: "50%",
                backgroundColor: "green",
                mr: 0.5,
              }}
            />
            <Box>Výpůjčka probíhá</Box>
          </Box>
          <Box sx={{ display: "flex", mr: 2 }}>
            <Box
              sx={{
                width: 15,
                height: 15,
                borderRadius: "50%",
                backgroundColor: "red",
                mr: 0.5,
              }}
            />
            <Box>Výpůjčka po termínu</Box>
          </Box>
          {/*<Box sx={{ display: "flex", mr: 2 }}>*/}
          {/*  <Box*/}
          {/*    sx={{*/}
          {/*      width: 15,*/}
          {/*      height: 15,*/}
          {/*      borderRadius: "50%",*/}
          {/*      backgroundColor: "silver",*/}
          {/*      mr: 0.5,*/}
          {/*    }}*/}
          {/*  />*/}
          {/*  <Box>Výpůjčka ukončena</Box>*/}
          {/*</Box>*/}
        </Box>

        <Box sx={{ display: "flex", backgroundColor: "#ffffff" }}>
          <Box
            sx={{
              backgroundColor: "#ffffff",
              width: 200,
              minWidth: 200,
              maxWidth: 200,
              border: "solid 1px rgba(0, 0, 0, 0.06)",
              fontWeight: "bold",
              padding: 1,
              boxSizing: "border-box",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <IconButton
                onClick={() => {
                  moveCalendar(-1);
                }}
              >
                <ArrowCircleLeft />
              </IconButton>
              <Box sx={{ fontSize: 13 }}>
                {getMonthName(boundaryStartDate)}{" "}
                {boundaryStartDate.getFullYear()}
              </Box>
              <IconButton
                onClick={() => {
                  moveCalendar(1);
                }}
              >
                <ArrowCircleRight />
              </IconButton>
            </Box>
          </Box>
          {columns().map((column) => {
            return (
              <Box
                key={column.toString()}
                sx={{
                  width: width,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  border: "solid 1px rgba(0, 0, 0, 0.06)",
                  fontWeight: "bold",
                  padding: 0.25,
                  boxSizing: "border-box",
                  backgroundColor: isNow(column)
                    ? "#ffffaa"
                    : isWeekday(column)
                    ? "rgba(0, 0, 0, 0.12)"
                    : "#ffffff",
                }}
              >
                <small>{getDayName(column)}</small>
                <br />
                <small>{column.getDate()}</small>
              </Box>
            );
          })}
        </Box>
        {rows().map((row) => {
          return (
            <Box sx={{ display: "flex" }}>
              <Box
                sx={{
                  backgroundColor: "#ffffff",
                  width: 200,
                  minWidth: 200,
                  maxWidth: 200,
                  border: "solid 1px rgba(0, 0, 0, 0.06)",
                  fontWeight: "bold",
                  padding: 1,
                  boxSizing: "border-box",
                  cursor: "pointer",
                  whiteSpace: "no-wrap",
                }}
                onClick={() => {
                  if (userIsAdmin) {
                    dispatch(formItemSet(row));
                  }
                }}
              >
                {row.inventoryNumber +
                  ": " +
                  ItemModel.fields[ItemModel.fieldCodes.TITLE].valueGetter(row)}
              </Box>
              {columns().map((column) => {
                return <>{getBox(row, column)}</>;
              })}
            </Box>
          );
        })}
      </Box>
    </>
  );
};

export default Calendar;
