export const SNACKBAR_ADD = "SNACKBAR_ADD";
export const SNACKBAR_REMOVE = "SNACKBAR_REMOVE";

export const FORM_USER_SET = "FORM_USER_SET";
export const FORM_ITEM_SET = "FORM_ITEM_SET";
export const FORM_BORROWING_SET = "FORM_BORROWING_SET";

export const USER_SET = "USER_SET";

export const DIALOG_FORM_ITEM_SET = "DIALOG_FORM_ITEM_SET";

export const FORM_FIELD_UPDATE = "FORM_FIELD_UPDATE";
