import { createSelector } from "reselect";

export const selectSnackbars = (state) => {
  return state.app.snackbars;
};

export const selectFormUser = (state) => state.app.formUser;
export const selectFormItem = (state) => state.app.formItem;
export const selectFormBorrowing = (state) => state.app.formBorrowing;
export const selectUser = (state) => state.app.user;
export const selectUserIsAdmin = (state) => state.app.user?.isAdmin || false;

export const selectDialogFormItemOpen = (state) => state.app.dialogFormItemOpen;

export const selectForm = createSelector(
  [
    (state, params) => {
      return state;
    },
    (state, params) => {
      return params.formName;
    },
  ],
  (state, formName) => {
    return state.app[formName];
  }
);

export const selectFormFieldValue = createSelector(
  [
    selectForm,
    (state, params) => {
      return params.fieldName;
    },
  ],
  (form, fieldName) => {
    return form[fieldName];
  }
);

export const selectFormMode = createSelector([selectForm], (form) => {
  if (!form) {
    return "CREATE";
  }
  return form.hasOwnProperty("id") ? "UPDATE" : "CREATE";
});

export const selectFormItemValue = createSelector(
  [
    selectFormItem,
    (state, params) => {
      return params.name;
    },
  ],
  (form, name) => {
    return form[name];
  }
);
