import React from "react";
import {Button} from "@mui/material";
import {formBorrowingSet} from "../../actions/appActions";
import {createNewBorrowing} from "../../library/borrowing";
import {useDispatch} from "react-redux";
import BorrowingList from "./BorrowingList";

const Borrowing = () => {
    const dispatch = useDispatch();

    const handleCreate = () => {
        dispatch(formBorrowingSet(createNewBorrowing()));
    };

    return (
        <>
            <Button
                onClick={handleCreate}
                color={"primary"}
                variant={"contained"}
                sx={{mb: 3}}
            >
                Nová výpůjčka
            </Button>
            <BorrowingList/>
        </>
    );
};

export default Borrowing;
