import { applyMiddleware, createStore } from "redux";
import rootReducer from "../reducers/rootReducer";
import thunk from "redux-thunk";
import { logger } from "redux-logger/src";
import { api } from "../api/api";
import { save, load } from "redux-localstorage-simple";

const store = createStore(
  rootReducer,
  load({ states: ["app"] }),
  applyMiddleware(thunk, api.middleware, save({ states: ["app"] }), logger)
);

export default store;
