import { createTheme } from "@mui/material";
import { deepPurple, grey, pink } from "@mui/material/colors";

export const textField = {
  size: "small",
};

export const drawerWidthOpen = 200;
export const drawerWidthClosed = 64;

export const navigatorWidthOpen = 400;

export const objectCardHighlightedBgColor = "#d8272f";

export const theme = createTheme({
  palette: {
    background: {
      default: grey[50],
    },
    primary: {
      main: "#f5037e",
    },
    secondary: {
      main: "#a81a88",
    },
  },
});
