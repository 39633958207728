export const normalizeDt = (dt) => {
  dt.setHours(3, 0, 0, 0);
  return dt;
};

export const checkBorrowingOverlappingAgainstBorrowing = (b1, b2) => {
  if (b1.item !== b2.item) {
    return false;
  }

  if (b1.hasOwnProperty("id") && b2.hasOwnProperty("id") && b1.id === b2.id) {
    return false;
  }

  const b1DateFrom = normalizeDt(new Date(b1.estimatedDateFrom));
  const b1DateTo = normalizeDt(new Date(b1.estimatedDateTo));

  if (b1DateTo < b1DateFrom) {
    //b1DateTo is before b1DateFrom
    return false;
  }

  const b2DateFrom = normalizeDt(new Date(b2.estimatedDateFrom));
  const b2DateTo = normalizeDt(new Date(b2.estimatedDateTo));

  if (b2DateTo < b2DateFrom) {
    return false;
  }

  return b1DateFrom <= b2DateTo && b2DateFrom <= b1DateTo;
};

export const checkBorrowingOverlappingAgainstList = (b, list) => {
  if (b.hasOwnProperty("items")) {
    return b.items.some((item) => {
      const b1 = {
        id: b.hasOwnProperty("id") ? b.id : -1,
        item: item,
        estimatedDateFrom: b.estimatedDateFrom,
        estimatedDateTo: b.estimatedDateTo,
        dateTo: b.dateTo,
      };
      return list
        .filter((b2) => {
          return !b2.dateTo; //ověřujeme jenom ty výpůjčky, které nejsou vrácené
        })
        .some((b2) => {
          return checkBorrowingOverlappingAgainstBorrowing(b1, b2);
        });
    });
  }

  return list.some((b2) => {
    return checkBorrowingOverlappingAgainstBorrowing(b, b2);
  });
};

export const getBorrowingStatus = (b1) => {
  if (!!b1.dateTo) {
    //výpůjčka vrácena
    return "closed";
  }

  const now = normalizeDt(new Date());

  const dateFrom = normalizeDt(new Date(b1.estimatedDateFrom));
  const dateTo = normalizeDt(new Date(b1.estimatedDateTo));

  if (now < dateFrom) {
    return "ready";
  }

  if (dateTo < now) {
    return "afterDeadline";
  }

  return "running";
};

export const getBorrowingColor = (b1) => {
  switch (getBorrowingStatus(b1)) {
    case "closed":
      return "silver";
    case "ready":
      return "orange";
    case "afterDeadline":
      return "red";
    case "running":
      return "green";
  }
};
