import React from "react";
import {
  selectDialogFormItemOpen,
  selectForm,
  selectFormMode,
  selectUserIsAdmin,
} from "../../selectors/selectors";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { snackbarAdd } from "../../actions/appActions";
import {
  useCreateItemMutation,
  useDeleteItemMutation,
  useUpdateItemMutation,
} from "../../api/api";
import { buildForm } from "../../library/item";
import FormField from "../Form/FormField";
import store from "../../store/store";
import { DIALOG_FORM_ITEM_SET } from "../../constants/actionType";

const MODE_UPDATE = "UPDATE";
const MODE_CREATE = "CREATE";

const ItemForm = () => {
  const dispatch = useDispatch();
  const userIsAdmin = useSelector(selectUserIsAdmin);
  const [createItem, createResult] = useCreateItemMutation();
  const [updateItem, updateResult] = useUpdateItemMutation();
  const [deleteItem, deleteResult] = useDeleteItemMutation();

  const mode = useSelector((state) => {
    return selectFormMode(state, { formName: "formItem" });
  });

  const open = useSelector(selectDialogFormItemOpen);

  const handleClose = () => {
    dispatch({
      type: DIALOG_FORM_ITEM_SET,
      payload: {
        open: false,
      },
    });
  };

  const handleSubmit = () => {
    const formItem = selectForm(store.getState(), { formName: "formItem" });
    const body = { ...formItem };
    if (mode === MODE_CREATE) {
      createItem({
        body: body,
      });
    }
    if (mode === MODE_UPDATE) {
      updateItem({
        id: body.id,
        body: body,
      });
    }
  };

  const handleDelete = () => {
    const formItem = selectForm(store.getState(), { formName: "formItem" });
    deleteItem({ id: formItem.id });
  };

  React.useEffect(() => {
    if (
      !createItem.isLoading &&
      !createItem.isError &&
      createResult.isSuccess
    ) {
      dispatch(
        snackbarAdd({
          severity: "success",
          message: "Předmět byl vytvořen.",
        })
      );
      handleClose();
    }
  }, [createResult]);

  React.useEffect(() => {
    if (
      !updateResult.isLoading &&
      !updateResult.isError &&
      updateResult.isSuccess
    ) {
      dispatch(
        snackbarAdd({
          severity: "success",
          message: "Předmět byl upraven.",
        })
      );
      handleClose();
    }
  }, [updateResult]);

  React.useEffect(() => {
    if (
      !deleteResult.isLoading &&
      !deleteResult.isError &&
      deleteResult.isSuccess
    ) {
      dispatch(
        snackbarAdd({
          severity: "success",
          message: "Předmět byl odstraněn.",
        })
      );
      handleClose();
    }
  }, [deleteResult]);

  return (
    <Dialog open={open} onClose={handleClose}>
      {open && (
        <>
          <DialogTitle>
            {mode === MODE_UPDATE ? "Upravit předmět" : "Nový předmět"}
          </DialogTitle>
          <DialogContent>
            {buildForm().map((field, index) => {
              return (
                <FormField key={field.field} field={field} index={index} />
              );
            })}
          </DialogContent>
          <DialogActions>
            <Box sx={{ display: "flex", width: "100%" }}>
              <Box sx={{ flexGrow: 1 }}>
                {mode === MODE_UPDATE && userIsAdmin && (
                  <Button
                    disabled={
                      createResult.isLoading ||
                      updateResult.isLoading ||
                      deleteResult.isLoading
                    }
                    color={"error"}
                    onClick={handleDelete}
                  >
                    Odstranit
                  </Button>
                )}
              </Box>
              <Box>
                <Button
                  disabled={
                    createResult.isLoading ||
                    updateResult.isLoading ||
                    deleteResult.isLoading
                  }
                  color={"primary"}
                  variant={"contained"}
                  onClick={handleSubmit}
                >
                  Uložit
                </Button>
              </Box>
            </Box>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default ItemForm;
