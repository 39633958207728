import React from "react";
import { Button, Typography } from "@mui/material";
import { formUserSet } from "../../actions/appActions";
import { createNewUser } from "../../library/user";
import { useDispatch, useSelector } from "react-redux";
import UserList from "./UserList";
import { selectUserIsAdmin } from "../../selectors/selectors";

const User = () => {
  const dispatch = useDispatch();
  const userIsAdmin = useSelector(selectUserIsAdmin);

  const handleCreate = () => {
    dispatch(formUserSet(createNewUser()));
  };

  return (
    <>
      {userIsAdmin && (
        <Button
          onClick={handleCreate}
          color={"primary"}
          variant={"contained"}
          sx={{ mb: 3 }}
        >
          Nový uživatel
        </Button>
      )}
      <UserList />
    </>
  );
};

export default User;
