import React from "react";
import { Button } from "@mui/material";
import { formItemSet } from "../../actions/appActions";
import { buildNewObject } from "../../library/item";
import { useDispatch, useSelector } from "react-redux";
import ItemList from "./ItemList";
import { selectUserIsAdmin } from "../../selectors/selectors";

const Item = () => {
  const dispatch = useDispatch();
  const userIsAdmin = useSelector(selectUserIsAdmin);

  const handleCreate = () => {
    dispatch(formItemSet(buildNewObject()));
  };

  return (
    <>
      {userIsAdmin && (
        <Button
          onClick={handleCreate}
          color={"primary"}
          variant={"contained"}
          sx={{ mb: 3 }}
        >
          Nový předmět
        </Button>
      )}
      <ItemList />
    </>
  );
};

export default Item;
