export const createNewBorrowing = () => {
  return {
    user: null,
    items: [],
    estimatedDateFrom: null,
    estimatedDateTo: null,
    dateFrom: null,
    dateTo: null,
    event: null,
    note: null,
  };
};
