import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectFormFieldValue } from "../../selectors/selectors";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  TextField,
} from "@mui/material";
import { DatePicker } from "@mui/lab";
import { formFieldUpdate } from "../../actions/appActions";

const FormField = (props) => {
  const { field } = props;

  const name = field.field;

  const dispatch = useDispatch();

  const value = useSelector((state) => {
    return selectFormFieldValue(state, {
      formName: "formItem",
      fieldName: name,
    });
  });

  const handleChange = (event) => {
    dispatch(
      formFieldUpdate({
        formName: "formItem",
        fieldName: name,
        value: event.target.value,
      })
    );
  };

  return (
    <>
      {field.dataType === "STRING" && (
        <TextField
          sx={{ mt: 2 }}
          fullWidth
          name={name}
          value={value}
          label={field.label}
          onChange={handleChange}
        />
      )}
      {field.dataType === "NUMBER" && (
        <TextField
          type={"number"}
          sx={{ mt: 2 }}
          fullWidth
          name={name}
          value={value}
          label={field.label}
          onChange={handleChange}
        />
      )}
      {field.dataType === "BOOLEAN" && (
        <FormGroup sx={{ mt: 2 }}>
          <FormControlLabel
            control={
              <Checkbox
                name={name}
                checked={value}
                onChange={(event) => {
                  handleChange({
                    target: {
                      name: event.target.name,
                      value: event.target.checked,
                    },
                  });
                }}
              />
            }
            label={field.label}
          />
        </FormGroup>
      )}
      {field.dataType === "DATETIME" && (
        <FormControl fullWidth sx={{ mt: 2 }}>
          <DatePicker
            renderInput={(props) => (
              <TextField
                onKeyDown={(e) => {
                  e.preventDefault();
                }}
                {...props}
              />
            )}
            label={field.label}
            value={!value ? null : value}
            onChange={(newValue) => {
              handleChange({
                target: {
                  name: name,
                  value: newValue,
                },
              });
            }}
            views={["day"]}
            onKeyDown={(e) => {
              e.preventDefault();
            }}
          />
        </FormControl>
      )}
    </>
  );
};

export default FormField;
