import React from "react";
import { Box, Button, IconButton } from "@mui/material";
import { formBorrowingSet, snackbarAdd } from "../../actions/appActions";
import { useDispatch, useSelector } from "react-redux";
import { Edit } from "@mui/icons-material";
import {
  useGetBorrowingsAllQuery,
  useGetItemsAllQuery,
  useGetUsersAllQuery,
  useUpdateBorrowingMutation,
} from "../../api/api";
import { csCZ, DataGrid, GridToolbar } from "@mui/x-data-grid";
import { selectUser, selectUserIsAdmin } from "../../selectors/selectors";
import { formatDate } from "../../utils/utils";
import { ItemModel } from "../../library/item";
import { getBorrowingColor } from "../../utils/borrowingLogic";
import BorrowingListActions from "./BorrowingListActions";

const BorrowingList = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const userIsAdmin = useSelector(selectUserIsAdmin);

  const {
    data: borrowingList = [],
    error: borrowingError,
    isLoading: borrowingIsLoading,
  } = useGetBorrowingsAllQuery();

  const {
    data: itemList = [],
    error: itemError,
    isLoading: itemIsLoading,
  } = useGetItemsAllQuery();

  const {
    data: userList = [],
    error: userError,
    isLoading: userIsLoading,
  } = useGetUsersAllQuery();

  const columns = [
    {
      field: "borrowed",
      flex: 0.2,
      headerName: "",
      renderCell: (params) => {
        return (
          <Box
            sx={{
              width: 15,
              height: 15,
              borderRadius: "50%",
              backgroundColor: getBorrowingColor(params.row),
            }}
          />
        );
      },
    },
    { field: "id", headerName: "#", flex: 0.5 },
    {
      field: "user",
      flex: 2,
      headerName: "Uživatel",
      valueGetter: (params) => {
        const index = userList.findIndex((i) => {
          return i["@id"] === params.row.user;
        });
        if (index > -1) {
          return userList[index].email;
        }
        return "";
      },
    },
    {
      field: "item",
      flex: 1,
      headerName: "Předmět",
      valueGetter: (params) => {
        const index = itemList.findIndex((i) => {
          return i["@id"] === params.row.item;
        });
        if (index > -1) {
          return (
            ItemModel.fields[ItemModel.fieldCodes.TITLE].valueGetter(
              itemList[index]
            ) +
            " (" +
            itemList[index].inventoryNumber +
            ")"
          );
          // return itemList[index].title;
        }
        return "";
      },
    },
    { field: "event", headerName: "Event", flex: 2 },
    {
      field: "estimatedDateFrom",
      flex: 1,
      headerName: "Vypůjčeno od",
      valueFormatter: (params) => {
        return formatDate(params.value);
      },
    },
    {
      field: "estimatedDateTo",
      flex: 1,
      headerName: "Vypůjčeno do",
      valueFormatter: (params) => {
        return formatDate(params.value);
      },
      renderCell: (params) => {
        const now = new Date();
        const dt = new Date(params.row.estimatedDateTo);
        now.setHours(0, 0, 0, 0);
        dt.setHours(0, 0, 0, 0);
        return (
          <Box sx={{ color: !params.row.dateTo && now > dt ? "red" : "black" }}>
            {formatDate(params.value)}
          </Box>
        );
      },
    },
    {
      field: "dateTo",
      flex: 1,
      headerName: "Vráceno",
      valueFormatter: (params) => {
        return formatDate(params.value);
      },
    },
    { field: "note", headerName: "Poznámka", flex: 1 },
    {
      field: "actions",
      flex: 2,
      headerName: "Akce",
      renderCell: (params) => {
        const row = params.row;
        return <BorrowingListActions row={row} />;
      },
    },
  ];

  const getRows = () => {
    return borrowingList.filter((borrowing) => {
      if (userIsAdmin) {
        return true;
      } else {
        return borrowing.user === user["@id"];
      }
    });
  };

  return (
    <>
      <Box sx={{ display: "flex", mb: 2, fontSize: 13 }}>
        <Box sx={{ display: "flex", mr: 2 }}>
          <Box
            sx={{
              width: 15,
              height: 15,
              borderRadius: "50%",
              backgroundColor: "orange",
              mr: 0.5,
            }}
          />
          <Box>Výpůjčka v budoucnosti</Box>
        </Box>
        <Box sx={{ display: "flex", mr: 2 }}>
          <Box
            sx={{
              width: 15,
              height: 15,
              borderRadius: "50%",
              backgroundColor: "green",
              mr: 0.5,
            }}
          />
          <Box>Výpůjčka probíhá</Box>
        </Box>
        <Box sx={{ display: "flex", mr: 2 }}>
          <Box
            sx={{
              width: 15,
              height: 15,
              borderRadius: "50%",
              backgroundColor: "red",
              mr: 0.5,
            }}
          />
          <Box>Výpůjčka po termínu</Box>
        </Box>
        <Box sx={{ display: "flex", mr: 2 }}>
          <Box
            sx={{
              width: 15,
              height: 15,
              borderRadius: "50%",
              backgroundColor: "silver",
              mr: 0.5,
            }}
          />
          <Box>Výpůjčka ukončena</Box>
        </Box>
      </Box>
      <DataGrid
        columns={columns}
        rows={getRows()}
        autoHeight={true}
        components={{ Toolbar: GridToolbar }}
        localeText={csCZ.components.MuiDataGrid.defaultProps.localeText}
        isRowSelectable={false}
        sx={{ backgroundColor: "#ffffff" }}
      />
    </>
  );
};

export default BorrowingList;
