import React from "react";
import {
  selectFormBorrowing,
  selectUser,
  selectUserIsAdmin,
} from "../../selectors/selectors";
import { useDispatch, useSelector } from "react-redux";
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import { formBorrowingSet, snackbarAdd } from "../../actions/appActions";
import {
  useCreateBorrowingMutation,
  useDeleteBorrowingMutation,
  useGetBorrowingsAllQuery,
  useGetItemsAllQuery,
  useGetUsersAllQuery,
  useUpdateBorrowingMutation,
} from "../../api/api";
import { DatePicker } from "@mui/lab";
import { normalizeDt } from "../Calendar/Calendar";
import { ItemModel } from "../../library/item";
import { checkBorrowingOverlappingAgainstList } from "../../utils/borrowingLogic";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const MODE_UPDATE = "UPDATE";
const MODE_CREATE = "CREATE";

const BorrowingForm = () => {
  const {
    data: itemList = [],
    error: itemError,
    isLoading: itemIsLoading,
  } = useGetItemsAllQuery();

  const {
    data: userList = [],
    error: userError,
    isLoading: userIsLoading,
  } = useGetUsersAllQuery();

  const {
    data: borrowingList = [],
    error: borrowingError,
    isLoading: borrowingIsLoading,
  } = useGetBorrowingsAllQuery();

  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const userIsAdmin = useSelector(selectUserIsAdmin);
  const formBorrowing = useSelector(selectFormBorrowing);
  const [formError, setFormError] = React.useState(
    "Vyplňte prosím všechna políčka."
  );
  const [createBorrowing, createResult] = useCreateBorrowingMutation();
  const [updateBorrowing, updateResult] = useUpdateBorrowingMutation();
  const [deleteBorrowing, deleteResult] = useDeleteBorrowingMutation();

  const mode =
    !!formBorrowing && formBorrowing.hasOwnProperty("id")
      ? MODE_UPDATE
      : MODE_CREATE;

  const handleClose = () => {
    dispatch(formBorrowingSet(null));
  };

  const handleChange = (event) => {
    dispatch(
      formBorrowingSet({
        ...formBorrowing,
        [event.target.name]: event.target.value,
      })
    );
  };

  const handleSubmit = () => {
    if (mode === MODE_CREATE) {
      formBorrowing.items.forEach((itemId) => {
        const body = { ...formBorrowing, item: itemId };
        createBorrowing({
          body: body,
        });
      });
      dispatch(
        snackbarAdd({
          severity: "success",
          message: "Výpůjčka byla vytvořena.",
        })
      );
      handleClose();
    }
    if (mode === MODE_UPDATE) {
      updateBorrowing({
        id: formBorrowing.id,
        body: formBorrowing,
      });
    }
  };

  const handleDelete = () => {
    deleteBorrowing({ id: formBorrowing.id });
  };

  React.useEffect(() => {
    if (!!formBorrowing && !formBorrowing.user) {
      handleChange({
        target: {
          name: "user",
          value: user["@id"],
        },
      });
    }
  }, [formBorrowing]);

  // React.useEffect(() => {
  //   if (
  //     !createBorrowing.isLoading &&
  //     !createBorrowing.isError &&
  //     createResult.isSuccess
  //   ) {
  //     dispatch(
  //       snackbarAdd({
  //         severity: "success",
  //         message: "Výpůjčka byla vytvořena.",
  //       })
  //     );
  //     handleClose();
  //   }
  // }, [createResult]);

  React.useEffect(() => {
    if (
      !updateResult.isLoading &&
      !updateResult.isError &&
      updateResult.isSuccess
    ) {
      dispatch(
        snackbarAdd({
          severity: "success",
          message: "Výpůjčka byla upravena.",
        })
      );
      handleClose();
    }
  }, [updateResult]);

  React.useEffect(() => {
    if (
      !deleteResult.isLoading &&
      !deleteResult.isError &&
      deleteResult.isSuccess
    ) {
      dispatch(
        snackbarAdd({
          severity: "success",
          message: "Výpůjčka byla odstraněna.",
        })
      );
      handleClose();
    }
  }, [deleteResult]);

  React.useEffect(() => {
    setFormError(null);
    if (!!formBorrowing) {
      if (
        !formBorrowing.user ||
        formBorrowing.items.length === 0 ||
        !formBorrowing.estimatedDateFrom ||
        !formBorrowing.estimatedDateTo
      ) {
        setFormError("Vyplňte prosím všechna políčka.");
      } else {
        const dateFrom = new Date(formBorrowing.estimatedDateFrom);
        const dateTo = new Date(formBorrowing.estimatedDateTo);
        if (dateFrom < dateTo) {
          const overlapping = checkBorrowingOverlappingAgainstList(
            formBorrowing,
            borrowingList
          );
          if (overlapping) {
            setFormError("Předmět nelze na zvolené období si vypůjčit.");
          }
        } else {
          setFormError(
            "Datum výpůjčky od musí být dříve než datum výpůčky od."
          );
        }
      }
    }
  }, [formBorrowing]);

  return (
    <Dialog open={Boolean(formBorrowing)} onClose={handleClose}>
      {Boolean(formBorrowing) && (
        <>
          <DialogTitle>
            {mode === MODE_UPDATE ? "Upravit výpůjčku" : "Nová výpůjčka"}
          </DialogTitle>
          <DialogContent>
            <FormControl fullWidth sx={{ mt: 2 }}>
              <InputLabel>Uživatel</InputLabel>
              <Select
                disabled={!userIsAdmin || mode === MODE_UPDATE}
                name={"user"}
                value={formBorrowing.user}
                label="Uživatel"
                onChange={handleChange}
              >
                {userList.map((user) => {
                  return (
                    <MenuItem value={user["@id"]} key={user.id}>
                      {user.email}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>

            <FormControl fullWidth sx={{ mt: 2 }}>
              <Autocomplete
                value={formBorrowing.items}
                onChange={(event, newValue) => {
                  handleChange({
                    target: {
                      name: "items",
                      value: newValue,
                    },
                  });
                }}
                fullWidth
                multiple
                options={itemList
                  .filter((item) => {
                    return item.isAvailableForBorrowing;
                  })
                  .sort((a, b) => {
                    return a.inventoryNumber * 1 - b.inventoryNumber * 1;
                  })
                  .map((item) => {
                    return item["@id"];
                  })}
                disableCloseOnSelect
                getOptionLabel={(id) => {
                  const index = itemList.findIndex((item) => {
                    return id === item["@id"];
                  });
                  if (index === -1) {
                    return "";
                  }
                  const option = itemList[index];
                  return (
                    option.inventoryNumber +
                    ": " +
                    ItemModel.fields[ItemModel.fieldCodes.TITLE].valueGetter(
                      option
                    )
                  );
                }}
                renderOption={(props, option, { selected }) => {
                  let title = "";

                  const index = itemList.findIndex((item) => {
                    return option === item["@id"];
                  });
                  if (index === -1) {
                    title = "";
                  }
                  const item = itemList[index];
                  title =
                    item.inventoryNumber +
                    ": " +
                    ItemModel.fields[ItemModel.fieldCodes.TITLE].valueGetter(
                      item
                    );

                  return (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {title}
                    </li>
                  );
                }}
                renderInput={(params) => (
                  <TextField {...params} fullWidth label="Předmět" />
                )}
              />
            </FormControl>

            {/*<FormControl fullWidth sx={{ mt: 2 }}>*/}
            {/*  <InputLabel>Předmět</InputLabel>*/}
            {/*  <Select*/}
            {/*    disabled={mode === MODE_UPDATE}*/}
            {/*    name={"items"}*/}
            {/*    value={formBorrowing.items}*/}
            {/*    label="Předmět"*/}
            {/*    onChange={handleChange}*/}
            {/*    multiple={true}*/}
            {/*    renderValue={(ids) => {*/}
            {/*      return itemList*/}
            {/*        .filter((item) => {*/}
            {/*          return ids.includes(item["@id"]);*/}
            {/*        })*/}
            {/*        .map((item) => {*/}
            {/*          return (*/}
            {/*            ItemModel.fields[*/}
            {/*              ItemModel.fieldCodes.TITLE*/}
            {/*            ].valueGetter(item) +*/}
            {/*            " (" +*/}
            {/*            item.inventoryNumber +*/}
            {/*            ")"*/}
            {/*          );*/}
            {/*        })*/}
            {/*        .join(", ");*/}
            {/*    }}*/}
            {/*  >*/}
            {/*    {itemList*/}
            {/*      .filter((item) => {*/}
            {/*        return item.isAvailableForBorrowing;*/}
            {/*      })*/}
            {/*      .map((item) => {*/}
            {/*        return (*/}
            {/*          <MenuItem value={item["@id"]} key={item.id}>*/}
            {/*            <Checkbox*/}
            {/*              checked={*/}
            {/*                formBorrowing.items.indexOf(item["@id"]) > -1*/}
            {/*              }*/}
            {/*            />*/}
            {/*            <ListItemText*/}
            {/*              primary={*/}
            {/*                ItemModel.fields[*/}
            {/*                  ItemModel.fieldCodes.TITLE*/}
            {/*                ].valueGetter(item) +*/}
            {/*                " (" +*/}
            {/*                item.inventoryNumber +*/}
            {/*                ")"*/}
            {/*              }*/}
            {/*            />*/}
            {/*          </MenuItem>*/}
            {/*        );*/}
            {/*      })}*/}
            {/*  </Select>*/}
            {/*</FormControl>*/}
            <FormControl fullWidth sx={{ mt: 2 }}>
              <TextField
                label={"Event"}
                name={"event"}
                value={formBorrowing.event}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl fullWidth sx={{ mt: 2 }}>
              <DatePicker
                renderInput={(props) => (
                  <TextField
                    onKeyDown={(e) => {
                      e.preventDefault();
                    }}
                    {...props}
                  />
                )}
                label="Vypůjčeno od"
                value={formBorrowing.estimatedDateFrom}
                onChange={(newValue) => {
                  handleChange({
                    target: {
                      name: "estimatedDateFrom",
                      value: newValue,
                    },
                  });
                }}
                views={["day"]}
                onKeyDown={(e) => {
                  e.preventDefault();
                }}
              />
            </FormControl>
            <FormControl fullWidth sx={{ mt: 2 }}>
              <DatePicker
                renderInput={(props) => (
                  <TextField
                    onKeyDown={(e) => {
                      e.preventDefault();
                    }}
                    {...props}
                  />
                )}
                label="Vypůjčeno do"
                value={formBorrowing.estimatedDateTo}
                onChange={(newValue) => {
                  handleChange({
                    target: {
                      name: "estimatedDateTo",
                      value: newValue,
                    },
                  });
                }}
                views={["day"]}
                onKeyDown={(e) => {
                  e.preventDefault();
                }}
              />
            </FormControl>
            <FormControl fullWidth sx={{ mt: 2 }}>
              <TextField
                label={"Poznámka"}
                name={"note"}
                value={formBorrowing.note}
                onChange={handleChange}
              />
            </FormControl>
            {formError && (
              <Alert severity="error" sx={{ mt: 2 }}>
                {formError}
              </Alert>
            )}
          </DialogContent>
          <DialogActions>
            <Box sx={{ display: "flex", width: "100%" }}>
              <Box sx={{ flexGrow: 1 }}>
                {mode === MODE_UPDATE && userIsAdmin && (
                  <Button
                    disabled={
                      createResult.isLoading ||
                      updateResult.isLoading ||
                      deleteResult.isLoading
                    }
                    color={"error"}
                    onClick={handleDelete}
                  >
                    Odstranit
                  </Button>
                )}
              </Box>
              <Box>
                <Button
                  disabled={
                    createResult.isLoading ||
                    updateResult.isLoading ||
                    deleteResult.isLoading ||
                    !!formError
                  }
                  color={"primary"}
                  variant={"contained"}
                  onClick={handleSubmit}
                >
                  Uložit
                </Button>
              </Box>
            </Box>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default BorrowingForm;
