import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectSnackbars } from "../../selectors/selectors";
import { Alert } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import { snackbarRemove } from "../../actions/appActions";

const SnackbarNotifier = () => {
  const snackbars = useSelector(selectSnackbars);
  const dispatch = useDispatch();

  const [closingSnackbarId, setClosingSnackbarId] = React.useState(null);

  const handleCloseSnackbar = (id) => {
    setClosingSnackbarId(id);
    setTimeout(() => {
      dispatch(snackbarRemove(id));
    }, 2000);
  };

  return (
    <>
      {snackbars.map((snackbar) => {
        return (
          <Snackbar
            key={snackbar.id}
            open={closingSnackbarId !== snackbar.id}
            autoHideDuration={3000}
            onClose={(event, reason) => {
              handleCloseSnackbar(snackbar.id);
            }}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          >
            <Alert
              onClose={(event, reason) => {
                handleCloseSnackbar(snackbar.id);
              }}
              severity={snackbar.severity}
              variant="filled"
              sx={{ width: "100%" }}
            >
              {snackbar.message}
            </Alert>
          </Snackbar>
        );
      })}
    </>
  );
};

export default SnackbarNotifier;
