import { createReducer } from "@reduxjs/toolkit";
import {
  DIALOG_FORM_ITEM_SET,
  FORM_BORROWING_SET,
  FORM_FIELD_UPDATE,
  FORM_ITEM_SET,
  FORM_USER_SET,
  SNACKBAR_ADD,
  SNACKBAR_REMOVE,
  USER_SET,
} from "../constants/actionType";
import produce from "immer";

const initialState = {
  formUser: null,
  formItem: null,
  dialogFormItemOpen: false,
  formBorrowing: null,
  snackbars: [],
  user: null,
};

const formUserSet = (state, action) => {
  return produce(state, (draft) => {
    draft.formUser = action.payload.formUser;
  });
};
const formItemSet = (state, action) => {
  return produce(state, (draft) => {
    draft.formItem = action.payload.formItem;
  });
};
const formBorrowingSet = (state, action) => {
  return produce(state, (draft) => {
    draft.formBorrowing = action.payload.formBorrowing;
  });
};

const snackbarAdd = (state, action) => {
  return produce(state, (draft) => {
    draft.snackbars.push(action.payload.snackbar);
  });
};

const snackbarRemove = (state, action) => {
  return produce(state, (draft) => {
    const index = draft.snackbars.findIndex((s) => s.id === action.payload.id);
    if (index !== -1) draft.snackbars.splice(index, 1);
  });
};

const userSet = (state, action) => {
  return produce(state, (draft) => {
    draft.user = action.payload.user;
  });
};

const formFieldUpdate = (state, action) => {
  const { formName, fieldName, value } = action.payload;

  return produce(state, (draft) => {
    draft[formName][fieldName] = value;
  });
};

const dialogFormItemSet = (state, action) => {
  return produce(state, (draft) => {
    draft.dialogFormItemOpen = action.payload.open;
  });
};

export const appReducer = createReducer(initialState, {
  [SNACKBAR_ADD]: snackbarAdd,
  [SNACKBAR_REMOVE]: snackbarRemove,
  [FORM_USER_SET]: formUserSet,
  [FORM_ITEM_SET]: formItemSet,
  [DIALOG_FORM_ITEM_SET]: dialogFormItemSet,
  [FORM_FIELD_UPDATE]: formFieldUpdate,
  [FORM_BORROWING_SET]: formBorrowingSet,
  [USER_SET]: userSet,
});
