import React from "react";
import { Alert, Box, Container, Paper, TextField } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { userSet } from "../../actions/appActions";
import { Navigate } from "react-router-dom";
import { selectUser } from "../../selectors/selectors";
import { saveTimeCheck } from "../../auth/timeCheck";

const initialFormError = null;
const initialFormSuccess = null;

const Login = () => {
  const dispatch = useDispatch();

  const user = useSelector(selectUser);

  const [loggedIn, setLoggedIn] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [formError, setFormError] = React.useState(initialFormError);
  const [formSuccess, setFormSuccess] = React.useState(initialFormSuccess);
  const [loading, setLoading] = React.useState(false);

  const handleSubmit = () => {
    setFormError(null);
    setFormSuccess(null);
    if (email.length > 0) {
      setLoading(true);
      const url =
        process.env.REACT_APP_BASE_URL + "/auth/checkByEmail?email=" + email;
      axios
        .get(url)
        .then((response) => {
          if (response.status === 200) {
            setFormSuccess(
              "Zkontrolujte prosím svoji e-mailovou schránku. Poslali jsme na ni zprávu s odkazem k přihlášení."
            );
          } else {
            setFormError("Přihlášení nebylo úspěšné, zkuste to prosím znovu.");
          }
        })
        .catch((e) => {
          setFormError("Přihlášení nebylo úspěšné, zkuste to prosím znovu.");
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setFormError("Vyplňte prosím svůj e-mail.");
    }
  };

  React.useEffect(() => {
    const params = {};
    if (window.location.hasOwnProperty("search")) {
      window.location.search
        .substring(1)
        .split("&")
        .forEach((a) => {
          const f = a.split("=");
          params[f[0]] = f[1];
        });
    }

    if (
      params.hasOwnProperty("v") &&
      params.v === "check" &&
      params.hasOwnProperty("t")
    ) {
      setLoading(true);
      const url =
        process.env.REACT_APP_BASE_URL + "/auth/checkByToken?token=" + params.t;
      axios
        .get(url)
        .then((response) => {
          if (response.status === 200) {
            setFormSuccess("Přihlášení bylo úspěšné.");
            const d = response.data;
            d["@id"] = "/users/" + response.data.id;
            dispatch(userSet(d));
            saveTimeCheck();
          } else {
            setFormError("Přihlášení nebylo úspěšné, zkuste to prosím znovu.");
          }
        })
        .catch((e) => {
          setFormError("Přihlášení nebylo úspěšné, zkuste to prosím znovu.");
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, []);

  React.useEffect(() => {
    if (!!user) {
      setLoggedIn(true);
    }
  }, [user]);

  if (loggedIn) {
    return <Navigate to="/" />;
  }

  return (
    <>
      <Container component="main" maxWidth="xs" sx={{ mt: 8 }}>
        <Paper sx={{ border: "1px solid #eee" }} elevation={0}>
          <Box
            p={3}
            paddingTop={4}
            display="flex"
            flexDirection={"column"}
            alignItems="center"
          >
            <Box sx={{ "& img": { width: 200, height: "auto", mb: 2 } }}>
              <img
                alt="Prague Pride"
                src="https://prague.tv/images/customer/logo/prague-pride_gallery-big.jpg"
              />
            </Box>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              autoComplete="email"
              autoFocus
              label="E-mail"
              name="email"
              value={email}
              onChange={(event) => {
                setEmail(event.target.value);
              }}
            />
            {formError && (
              <Alert severity="error" sx={{ mt: 2, width: "90%" }}>
                {formError}
              </Alert>
            )}
            {formSuccess && (
              <Alert severity="success" sx={{ mt: 2, width: "90%" }}>
                {formSuccess}
              </Alert>
            )}
            {!formSuccess && (
              <LoadingButton
                loading={loading}
                disabled={loading}
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disableElevation
                sx={{ mt: 3, mb: 2 }}
                onClick={handleSubmit}
              >
                Přihlásit
              </LoadingButton>
            )}
          </Box>
        </Paper>
      </Container>
    </>
  );
};

export default Login;
