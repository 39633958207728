import React from "react";
import { selectFormUser } from "../../selectors/selectors";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  Checkbox,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  TextField,
} from "@mui/material";
import { formUserSet, snackbarAdd } from "../../actions/appActions";
import {
  useCreateUserMutation,
  useDeleteUserMutation,
  useUpdateUserMutation,
} from "../../api/api";

const MODE_UPDATE = "UPDATE";
const MODE_CREATE = "CREATE";

const UserForm = () => {
  const dispatch = useDispatch();
  const formUser = useSelector(selectFormUser);
  const [createUser, createResult] = useCreateUserMutation();
  const [updateUser, updateResult] = useUpdateUserMutation();
  const [deleteUser, deleteResult] = useDeleteUserMutation();

  const mode =
    !!formUser && formUser.hasOwnProperty("id") ? MODE_UPDATE : MODE_CREATE;

  const handleClose = () => {
    dispatch(formUserSet(null));
  };

  const handleChange = (event) => {
    dispatch(
      formUserSet({ ...formUser, [event.target.name]: event.target.value })
    );
  };

  const handleSubmit = () => {
    if (mode === MODE_CREATE) {
      createUser({
        body: formUser,
      });
    }
    if (mode === MODE_UPDATE) {
      updateUser({
        id: formUser.id,
        body: formUser,
      });
    }
  };

  const handleDelete = () => {
    deleteUser({ id: formUser.id });
  };

  React.useEffect(() => {
    if (
      !createUser.isLoading &&
      !createUser.isError &&
      createResult.isSuccess
    ) {
      dispatch(
        snackbarAdd({
          severity: "success",
          message: "Uživatel byl vytvořen.",
        })
      );
      handleClose();
    }
  }, [createResult]);

  React.useEffect(() => {
    if (
      !updateResult.isLoading &&
      !updateResult.isError &&
      updateResult.isSuccess
    ) {
      dispatch(
        snackbarAdd({
          severity: "success",
          message: "Uživatel byl upraven.",
        })
      );
      handleClose();
    }
  }, [updateResult]);

  React.useEffect(() => {
    if (
      !deleteResult.isLoading &&
      !deleteResult.isError &&
      deleteResult.isSuccess
    ) {
      dispatch(
        snackbarAdd({
          severity: "success",
          message: "Uživatel byl odstraněn.",
        })
      );
      handleClose();
    }
  }, [deleteResult]);

  return (
    <Dialog open={Boolean(formUser)} onClose={handleClose}>
      {Boolean(formUser) && (
        <>
          <DialogTitle>
            {mode === MODE_UPDATE ? "Upravit uživatele" : "Nový uživatel"}
          </DialogTitle>
          <DialogContent>
            <TextField
              sx={{ mt: 2 }}
              autoFocus={true}
              fullWidth
              name={"email"}
              value={formUser.email}
              label={"E-mail"}
              onChange={handleChange}
            />
            <FormGroup sx={{ mt: 2 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="isAdmin"
                    checked={formUser.isAdmin}
                    onChange={(event) => {
                      handleChange({
                        target: {
                          name: event.target.name,
                          value: event.target.checked,
                        },
                      });
                    }}
                  />
                }
                label="Administrátor"
              />
            </FormGroup>
            <Collapse in={formUser.isAdmin}>
              <FormGroup sx={{ mt: 2 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="sendNotifications"
                      checked={formUser.sendNotifications}
                      onChange={(event) => {
                        handleChange({
                          target: {
                            name: event.target.name,
                            value: event.target.checked,
                          },
                        });
                      }}
                    />
                  }
                  label="Zasílat notifikace"
                />
              </FormGroup>
            </Collapse>
          </DialogContent>
          <DialogActions>
            <Box sx={{ display: "flex", width: "100%" }}>
              <Box sx={{ flexGrow: 1 }}>
                {mode === MODE_UPDATE && (
                  <Button
                    disabled={
                      createResult.isLoading ||
                      updateResult.isLoading ||
                      deleteResult.isLoading
                    }
                    color={"error"}
                    onClick={handleDelete}
                  >
                    Odstranit
                  </Button>
                )}
              </Box>
              <Box>
                <Button
                  disabled={
                    createResult.isLoading ||
                    updateResult.isLoading ||
                    deleteResult.isLoading
                  }
                  color={"primary"}
                  variant={"contained"}
                  onClick={handleSubmit}
                >
                  Uložit
                </Button>
              </Box>
            </Box>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default UserForm;
