import { formatDate } from "../utils/utils";

export class ItemModel {
  static fieldCodes = {
    CATEGORY: "CATEGORY",
    INVENTORY_NUMBER: "INVENTORY_NUMBER",
    SERIAL_NUMBER: "SERIAL_NUMBER",
    TITLE: "TITLE",
    PRODUCER: "PRODUCER",
    MODEL: "MODEL",
    COLOR: "COLOR",
    IS_AVAILABLE_FOR_BORROWING: "IS_AVAILABLE_FOR_BORROWING",
    STATUS: "STATUS",
    NOTE: "NOTE",
    BOUGHT_AT: "BOUGHT_AT",
    BOUGHT_BY: "BOUGHT_BY",
    BOUGHT_WHERE: "BOUGHT_WHERE",
    BOUGHT: "BOUGHT",
    PRICE: "PRICE",
    DOCUMENT_NUMBER: "DOCUMENT_NUMBER",
  };

  static fields = {
    [this.fieldCodes.CATEGORY]: {
      dataType: "STRING",
      label: "Kategorie",
      field: "category",
      valueGetter: (object) => object.category,
    },
    [this.fieldCodes.INVENTORY_NUMBER]: {
      dataType: "STRING",
      label: "Inv. číslo",
      field: "inventoryNumber",
      valueGetter: (object) => object.inventoryNumber,
    },
    [this.fieldCodes.SERIAL_NUMBER]: {
      dataType: "STRING",
      label: "V. číslo",
      field: "serialNumber",
      valueGetter: (object) => object.serialNumber,
    },
    [this.fieldCodes.TITLE]: {
      dataType: "STRING",
      label: "Název",
      valueGetter: (object) => {
        const values = [];
        if (!!object.producer) {
          values.push(object.producer);
        }
        if (!!object.model) {
          values.push(object.model);
        }
        if (!!object.color) {
          values.push(object.color);
        }
        return values.join(" ");
      },
    },
    [this.fieldCodes.PRODUCER]: {
      dataType: "STRING",
      label: "Výrobce",
      field: "producer",
      valueGetter: (object) => object.producer,
    },
    [this.fieldCodes.MODEL]: {
      dataType: "STRING",
      label: "Model",
      field: "model",
      valueGetter: (object) => object.model,
    },
    [this.fieldCodes.COLOR]: {
      dataType: "STRING",
      label: "Barva",
      field: "color",
      valueGetter: (object) => object.color,
    },
    [this.fieldCodes.IS_AVAILABLE_FOR_BORROWING]: {
      dataType: "BOOLEAN",
      label: "Lze vypůjčit?",
      field: "isAvailableForBorrowing",
      valueGetter: (object) => (object.isAvailableForBorrowing ? "ANO" : "NE"),
    },
    [this.fieldCodes.STATUS]: {
      dataType: "STRING",
      label: "Stav",
      field: "status",
      valueGetter: (object) => object.status,
    },
    [this.fieldCodes.NOTE]: {
      dataType: "STRING",
      label: "Poznámka",
      field: "note",
      valueGetter: (object) => object.note,
    },
    [this.fieldCodes.BOUGHT_AT]: {
      dataType: "DATETIME",
      label: "Pořízeno kdy",
      field: "boughtAt",
      valueGetter: (object) => object.boughtAt,
      valueFormatter: (object) => {
        return formatDate(object.boughtAt);
      },
    },
    [this.fieldCodes.BOUGHT_BY]: {
      dataType: "STRING",
      label: "Pořízeno kým",
      field: "boughtBy",
      valueGetter: (object) => object.boughtBy,
    },
    [this.fieldCodes.BOUGHT_WHERE]: {
      dataType: "STRING",
      label: "Pořízeno kde",
      field: "boughtWhere",
      valueGetter: (object) => object.boughtWhere,
    },
    [this.fieldCodes.BOUGHT]: {
      dataType: "STRING",
      label: "Pořízeno",
      valueGetter: (object) => {
        const values = [];
        if (!!object.boughtAt) {
          values.push(formatDate(object.boughtAt));
        }
        if (!!object.boughtBy) {
          values.push(object.boughtBy);
        }
        if (!!object.boughtWhere) {
          values.push(object.boughtWhere);
        }
        return values.join(" ");
      },
    },
    [this.fieldCodes.PRICE]: {
      dataType: "NUMBER",
      label: "Cena",
      field: "price",
      valueGetter: (object) => object.price,
    },
    [this.fieldCodes.DOCUMENT_NUMBER]: {
      dataType: "STRING",
      label: "Číslo dokladu",
      field: "documentNumber",
      valueGetter: (object) => object.documentNumber,
    },
  };
}

const getDefaultValue = (dataType) => {
  switch (dataType) {
    case "STRING": {
      return "";
    }
    case "DATETIME":
    case "NUMBER": {
      return null;
    }
    case "BOOLEAN": {
      return false;
    }
  }
};

export const buildNewObject = () => {
  const c = ItemModel.fieldCodes;
  const fieldCodes = [
    c.CATEGORY,
    c.INVENTORY_NUMBER,
    c.PRODUCER,
    c.MODEL,
    c.COLOR,
    c.SERIAL_NUMBER,
    c.IS_AVAILABLE_FOR_BORROWING,
    c.STATUS,
    c.NOTE,
    c.BOUGHT_AT,
    c.BOUGHT_BY,
    c.BOUGHT_WHERE,
    c.PRICE,
    c.DOCUMENT_NUMBER,
  ];
  const object = {};
  fieldCodes.forEach((fieldCode) => {
    const field = ItemModel.fields[fieldCode];
    object[field.field] = getDefaultValue(field.dataType);
  });

  return object;
};

export const buildTableColumns = ({ fieldCodes = [] }) => {
  const columns = [];
  fieldCodes.forEach((fieldCode) => {
    const field = ItemModel.fields[fieldCode];
    const column = {};
    if (field.hasOwnProperty("field")) {
      column.field = field.field;
    } else {
      column.field = fieldCode;
    }
    if (field.hasOwnProperty("label")) {
      column.headerName = field.label;
    }
    if (field.hasOwnProperty("valueGetter")) {
      column.valueGetter = (params) => {
        return field.valueGetter(params.row);
      };
    }
    if (field.hasOwnProperty("valueFormatter")) {
      column.valueFormatter = (params) => {
        return field.valueFormatter(params.row);
      };
    }
    column.flex = 1;
    columns.push(column);
  });

  return columns;
};

export const buildForm = () => {
  const form = [];

  const c = ItemModel.fieldCodes;
  const fieldCodes = [
    c.CATEGORY,
    c.INVENTORY_NUMBER,
    c.PRODUCER,
    c.MODEL,
    c.COLOR,
    c.SERIAL_NUMBER,
    c.IS_AVAILABLE_FOR_BORROWING,
    c.STATUS,
    c.NOTE,
    c.BOUGHT_AT,
    c.BOUGHT_BY,
    c.BOUGHT_WHERE,
    c.PRICE,
    c.DOCUMENT_NUMBER,
  ];

  fieldCodes.forEach((fieldCode) => {
    form.push(ItemModel.fields[fieldCode]);
  });

  return form;
};
