import React from "react";
import { Button, IconButton, Box } from "@mui/material";
import { formBorrowingSet, formItemSet } from "../../actions/appActions";
import { useDispatch, useSelector } from "react-redux";
import { Edit } from "@mui/icons-material";
import { useGetItemsAllQuery, useGetBorrowingsAllQuery } from "../../api/api";
import { csCZ, DataGrid, GridToolbar } from "@mui/x-data-grid";
import { selectUserIsAdmin } from "../../selectors/selectors";
import { buildTableColumns, ItemModel } from "../../library/item";
import { createNewBorrowing } from "../../library/borrowing";
import { normalizeDt } from "../../utils/borrowingLogic";

const ItemList = () => {
  const dispatch = useDispatch();
  const userIsAdmin = useSelector(selectUserIsAdmin);

  const {
    data: borrowingList = [],
    error: borrowingError,
    isLoading: borrowingIsLoading,
  } = useGetBorrowingsAllQuery();

  const {
    data: itemList = [],
    error: itemError,
    isLoading: itemIsLoading,
  } = useGetItemsAllQuery();

  const getRows = () => {
    return itemList;
  };

  const isNowBorrowed = (item) => {
    const now = normalizeDt(new Date());

    const index = borrowingList.findIndex((b) => {
      const dateFrom = normalizeDt(new Date(b.estimatedDateFrom));
      const dateTo = normalizeDt(
        !!b.dateTo ? new Date(b.dateTo) : new Date(b.estimatedDateTo)
      );
      return b.item === item["@id"] && dateFrom <= now && dateTo >= now;
    });

    return index > -1;
  };

  const getColumns = () => {
    let columns = [];
    columns.push({
      field: "borrowed",
      flex: 0.2,
      headerName: "",
      renderCell: (params) => {
        return (
          <Box
            sx={{
              width: 15,
              height: 15,
              borderRadius: "50%",
              backgroundColor: isNowBorrowed(params.row) ? "red" : "green",
            }}
          />
        );
      },
    });

    const buildedColumns = buildTableColumns({
      fieldCodes: [
        ItemModel.fieldCodes.CATEGORY,
        ItemModel.fieldCodes.INVENTORY_NUMBER,
        ItemModel.fieldCodes.TITLE,
        ItemModel.fieldCodes.IS_AVAILABLE_FOR_BORROWING,
        ItemModel.fieldCodes.STATUS,
        ItemModel.fieldCodes.NOTE,
        ItemModel.fieldCodes.BOUGHT,
        ItemModel.fieldCodes.PRICE,
        ItemModel.fieldCodes.DOCUMENT_NUMBER,
      ],
    });

    columns = columns.concat(buildedColumns);

    columns.push({
      field: "actions",
      flex: 2,
      headerName: "Akce",
      renderCell: (params) => {
        const row = params.row;
        return (
          <>
            {userIsAdmin && (
              <IconButton
                size={"small"}
                variant={"outlined"}
                color={"primary"}
                onClick={() => {
                  dispatch(formItemSet(row));
                }}
              >
                <Edit />
              </IconButton>
            )}
            <Button
              disabled={!row.isAvailableForBorrowing}
              size={"small"}
              variant={"contained"}
              color={"primary"}
              onClick={() => {
                const borrowing = createNewBorrowing();
                borrowing.item = row["@id"];
                dispatch(formBorrowingSet(borrowing));
              }}
            >
              Vypůjčit
            </Button>
          </>
        );
      },
    });

    return columns;
  };

  return (
    <>
      <Box sx={{ display: "flex", mb: 2, fontSize: 13 }}>
        <Box sx={{ display: "flex", mr: 2 }}>
          <Box
            sx={{
              width: 15,
              height: 15,
              borderRadius: "50%",
              backgroundColor: "green",
              mr: 0.5,
            }}
          />
          <Box>Dnes není vypůjčeno</Box>
        </Box>
        <Box sx={{ display: "flex", mr: 2 }}>
          <Box
            sx={{
              width: 15,
              height: 15,
              borderRadius: "50%",
              backgroundColor: "red",
              mr: 0.5,
            }}
          />
          <Box>Dnes je vypůjčeno</Box>
        </Box>
      </Box>
      <DataGrid
        columns={getColumns()}
        rows={getRows()}
        autoHeight={true}
        components={{ Toolbar: GridToolbar }}
        localeText={csCZ.components.MuiDataGrid.defaultProps.localeText}
        isRowSelectable={false}
        sx={{ backgroundColor: "#ffffff" }}
      />
    </>
  );
};

export default ItemList;
