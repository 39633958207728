import React from "react";
import { Button, IconButton } from "@mui/material";
import { formBorrowingSet, snackbarAdd } from "../../actions/appActions";
import { useDispatch, useSelector } from "react-redux";
import { Edit } from "@mui/icons-material";
import { useUpdateBorrowingMutation } from "../../api/api";
import { selectUser, selectUserIsAdmin } from "../../selectors/selectors";

const BorrowingListActions = (props) => {
  const { row } = props;

  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const userIsAdmin = useSelector(selectUserIsAdmin);

  const [updateBorrowing, updateResult] = useUpdateBorrowingMutation();

  const handleReturn = (borrowing) => {
    const { user, item, ...rest } = borrowing;
    updateBorrowing({
      id: borrowing.id,
      body: { ...rest, dateTo: new Date() },
    });
  };

  const handleNoReturn = (borrowing) => {
    const { user, item, ...rest } = borrowing;
    updateBorrowing({
      id: borrowing.id,
      body: { ...rest, dateTo: null },
    });
  };

  const canNoReturn = (borrowing) => {
    if (!borrowing.dateTo) {
      return false;
    }
    if (userIsAdmin) {
      return true;
    }
    const now = new Date();
    const dt = new Date(borrowing.dateTo);
    const diff = Math.abs(now - dt) / 36e5;
    if (diff <= 3) {
      return true;
    }

    return false;
  };

  React.useEffect(() => {
    if (
      !updateResult.isLoading &&
      !updateResult.isError &&
      updateResult.isSuccess
    ) {
      dispatch(
        snackbarAdd({
          severity: "success",
          message: "Výpůjčka byla aktualizována.",
        })
      );
    }
  }, [updateResult]);

  return (
    <>
      {userIsAdmin && (
        <IconButton
          disabled={updateResult.isLoading}
          sx={{ mr: 1 }}
          size={"small"}
          variant={"outlined"}
          color={"primary"}
          onClick={() => {
            dispatch(formBorrowingSet(row));
          }}
        >
          <Edit />
        </IconButton>
      )}
      {!row.dateTo && (
        <Button
          disabled={updateResult.isLoading}
          size={"small"}
          variant={"contained"}
          color={"primary"}
          onClick={() => {
            handleReturn(row);
          }}
        >
          Označit jako vrácené
        </Button>
      )}
      {!!row.dateTo && canNoReturn(row) && (
        <Button
          disabled={updateResult.isLoading}
          size={"small"}
          variant={"outlined"}
          color={"primary"}
          onClick={() => {
            handleNoReturn(row);
          }}
        >
          Označit jako nevrácené
        </Button>
      )}
    </>
  );
};

export default BorrowingListActions;
