import {
  DIALOG_FORM_ITEM_SET,
  FORM_BORROWING_SET,
  FORM_FIELD_UPDATE,
  FORM_ITEM_SET,
  FORM_USER_SET,
  SNACKBAR_ADD,
  SNACKBAR_REMOVE,
  USER_SET,
} from "../constants/actionType";

export const snackbarAdd =
  ({ severity, message }) =>
  (dispatch) => {
    dispatch({
      type: SNACKBAR_ADD,
      payload: {
        snackbar: {
          id: new Date().getTime(),
          severity,
          message,
        },
      },
    });
  };

export const snackbarRemove = (id) => (dispatch) => {
  dispatch({
    type: SNACKBAR_REMOVE,
    payload: {
      id,
    },
  });
};

export const formUserSet = (formUser) => (dispatch) => {
  dispatch({
    type: FORM_USER_SET,
    payload: {
      formUser,
    },
  });
};

export const formItemSet = (formItem) => (dispatch) => {
  dispatch({
    type: FORM_ITEM_SET,
    payload: {
      formItem,
    },
  });
  dispatch({
    type: DIALOG_FORM_ITEM_SET,
    payload: {
      open: true,
    },
  });
};

export const formBorrowingSet = (formBorrowing) => (dispatch) => {
  if (formBorrowing === null) {
    dispatch({
      type: FORM_BORROWING_SET,
      payload: {
        formBorrowing: null,
      },
    });
  } else {
    if (formBorrowing.hasOwnProperty("item")) {
      dispatch({
        type: FORM_BORROWING_SET,
        payload: {
          formBorrowing: {
            ...formBorrowing,
            items: [formBorrowing.item],
          },
        },
      });
    } else {
      dispatch({
        type: FORM_BORROWING_SET,
        payload: {
          formBorrowing,
        },
      });
    }
  }
};

export const userSet = (user) => (dispatch) => {
  dispatch({
    type: USER_SET,
    payload: {
      user,
    },
  });
};

export const formFieldUpdate =
  ({ formName, fieldName, value }) =>
  (dispatch) => {
    dispatch({
      type: FORM_FIELD_UPDATE,
      payload: {
        formName,
        fieldName,
        value,
      },
    });
  };
