export const formatDate = (dtString = null) => {
  if (!dtString) {
    return "";
  }
  const dt = new Date(dtString);
  return [dt.getDate(), dt.getMonth() + 1, dt.getFullYear()]
    .map((t) => {
      return t < 10 ? "0" + t : t;
    })
    .join(".");
};
