const KEY = "timeCheck";

const now = () => {
  return Math.floor(Date.now() / 1000);
};

export const testTimeCheck = () => {
  return now() - loadTimeCheck() < 7 * 24 * 60 * 60; //po sedmi dnech je uživatel automaticky odhlášen
};

export const saveTimeCheck = () => {
  localStorage.setItem(KEY, now() + "");
};

export const loadTimeCheck = () => {
  return localStorage.getItem(KEY) * 1;
};

export const removeTimeCheck = () => {
  localStorage.removeItem(KEY);
};
