import { combineReducers } from "redux";
import { appReducer } from "./appReducer";
import {api} from '../api/api';

const rootReducer = combineReducers({
  [api.reducerPath]: api.reducer,
  app: appReducer,
});

export default rootReducer;
