import React from "react";
import { Button } from "@mui/material";
import { formUserSet } from "../../actions/appActions";
import { useDispatch, useSelector } from "react-redux";
import { useGetUsersAllQuery } from "../../api/api";
import { csCZ, DataGrid, GridToolbar } from "@mui/x-data-grid";
import { selectUserIsAdmin } from "../../selectors/selectors";

const UserList = () => {
  const dispatch = useDispatch();
  const userIsAdmin = useSelector(selectUserIsAdmin);

  const {
    data: userList,
    error: userError,
    isLoading: userIsLoading,
  } = useGetUsersAllQuery();

  const columns = [
    { field: "email", flex: 1, headerName: "E-mail" },
    {
      field: "isAdmin",
      flex: 1,
      headerName: "Administrátor",
      renderCell: (params) => {
        return params.row.isAdmin ? "ANO" : "NE";
      },
    },
    {
      field: "actions",
      flex: 1,
      headerName: "Akce",
      renderCell: (params) => {
        const row = params.row;
        return (
          <>
            {userIsAdmin && (
              <Button
                size={"small"}
                variant={"outlined"}
                color={"primary"}
                onClick={() => {
                  dispatch(formUserSet(row));
                }}
              >
                Upravit
              </Button>
            )}
          </>
        );
      },
    },
  ];

  return (
    <DataGrid
      columns={columns}
      rows={userList}
      autoHeight={true}
      components={{ Toolbar: GridToolbar }}
      localeText={csCZ.components.MuiDataGrid.defaultProps.localeText}
      isRowSelectable={false}
      sx={{ backgroundColor: "#ffffff" }}
    />
  );
};

export default UserList;
